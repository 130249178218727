import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Signup.css';
import Crypto from './images/signup.png';
import firebase from './firebaseconfig';

const Signup = () => {
  const [email, setEmail] = useState('');

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Store the email in Firebase Realtime Database
    firebase
      .database()
      .ref('emails')
      .push(email)
      .then(() => {
        console.log('Email stored successfully');
        setEmail(''); // Reset the email input
        toast.success('Email stored successfully', { autoClose: 3000 }); // Display success notification
      })
      .catch((error) => {
        console.error('Error storing email:', error);
        toast.error('Error storing email'); // Display error notification
      });
  };

  return (
    <div className='signup'>
      <div className='container'>
        {/* left */}
        <div className='left'>
          <img src={Crypto} alt='' />
        </div>

        {/* right */}
        <div className='right'>
          <h2>Join us on an extraordinary journey of innovation!</h2>
          <p>
            Algotrends combines cutting-edge technology, AI-driven insights, and a passion for cryptocurrencies.
            Sign up and gain exclusive early access to invaluable predictions and analysis of your favorite cryptocurrencies.
          </p>
          <form className='input-container' onSubmit={handleSubmit}>
            <input type='email' placeholder='Enter your email' value={email} onChange={handleInputChange} />
            <button type='submit' className='btn'>Learn More</button>
          </form>
        </div>
      </div>
      <ToastContainer /> {/* Add ToastContainer component */}
    </div>
  );
};

export default Signup;
