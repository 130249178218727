// firebaseConfig.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';


const firebaseConfig = {
    apiKey: "AIzaSyAYDVt6The1QfLDIjSwPuoa1W_gVxshMXo",
    authDomain: "algotrendsai.firebaseapp.com",
    projectId: "algotrendsai",
    storageBucket: "algotrendsai.appspot.com",
    messagingSenderId: "599413534998",
    appId: "1:599413534998:web:b8dd836350ff0670ff6037",
    measurementId: "G-KLPZYZ4MH7"
};

firebase.initializeApp(firebaseConfig);

export default firebase;
