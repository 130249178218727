import React from 'react';
import logo from './images/logo.png';
import twitterLogo from './images/twitter.png'; // Import the Twitter logo image
import './Navbar.css';

const Navbar = () => {
  return (
    <div className='header'>
      <nav className='navbar'>
        <a href='/' className='logo'>
          <img src={logo} alt='logo' />
        </a>
        <div className='social-icons'>
          <a href='https://twitter.com/AlgoTrends_ai' target='_blank' rel='noopener noreferrer'>
            <img src={twitterLogo} alt='Twitter' className='twitterlogo' />
          </a>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
