import React from 'react'
import Navbar from './components/Navbar'
import Hero from './components/Hero'
import Featured from './components/Featured'
import Signup from './components/Signup'
function App() {
  return (
    <>
    <Navbar />
    <Hero />
    <Featured />
    <Signup />
    </>
  );
}

export default App;
