import React, { useState } from 'react';
import Typed from 'react-typed';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Hero.css';
import Crypto from './images/banner-min.png';
import firebase from './firebaseconfig';

const Hero = () => {
  const [email, setEmail] = useState('');

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Store the email in Firebase Realtime Database
    firebase
      .database()
      .ref('emails')
      .push(email)
      .then(() => {
        console.log('Email stored successfully');
        setEmail(''); // Reset the email input
        toast.success('Email stored successfully', { autoClose: 3000 });
      })
      .catch((error) => {
        console.error('Error storing email:', error);
      });
  };

  return (
    <div className='hero'>
      <div className='container'>
        {/* Left Side */}
        <div className='left'>
          <p1>Stay Ahead of the Game:</p1> AI-Powered Crypto Price Forecasts
          <h1>
            Decipher the future prices of&nbsp;&nbsp;
            <Typed
              className='btc'
              strings={['BTC', 'ETH', 'XRP', 'BNB']}
              typeSpeed={120}
              backSpeed={140}
              loop
            />
          </h1>
          <p>Unlock the power of Artificial Intelligence to shape your strategy</p>
          <form className='input-container' onSubmit={handleSubmit}>
            <input
              type='email'
              placeholder='Enter your email'
              value={email}
              onChange={handleInputChange}
            />
            <button type='submit' className='btn'>
              Learn More
            </button>
          </form>
        </div>

        {/* Right Side */}
        <div className='right'>
          <div className='img-container'>
            <img src={Crypto} alt='' />
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Hero;
